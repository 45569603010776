import axios from "axios";

export default {

    login(user, headers){
        return axios.post(`/login`, user, headers);
    },

    getInfoUser(data, headers){
      return axios.post(`/info_usr`, data, {headers});
    },
    
  getAllInfo(option, headers){
    return axios.get(`/${option}`, {headers});
  },

  getStatusImage(payload, headers){
    return axios.get(`/${payload.option}-img/${payload.image}`, {headers});
  },
  
  getInfoById(payload, headers){
    return axios.get(`/${payload.option}/${payload.id}`, {headers});
  },
 
  search(data, headers){
    return axios.get(`/buscar/${data.normalizedOpt}/${data.search}`, {headers});
  },

  searchById(data, headers){
    return axios.get(`/buscar/${data.normalizedOpt}/${data.id}/${data.search}`, {headers});
  },


 
  enviarMailContacto(data, headers){
    return axios.post('j43-wdrDE4s_wffs2/contacto', data, headers);
  },
  //metodos de eliminar, editar y agregar
  deleteItem(payload,token){
    return axios.delete(`/${payload.option}/delete`, {
        data: {
          id:payload.id
        },
        headers: {
          Authorization: token 
        } 
      });
  },
  
  editItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.put(`/${payload.option}/update`, payload.item, {headers} );
  },

  addItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.post(`/${payload.option}/create`, payload.item, {headers});
  },

  editImage(payload,token){
    let  headers = {
      Authorization: token
    }
    
    return axios.put(`/${payload.option}/upload/image/${payload.id}`, payload.item, {headers} );
  },
  deleteItemCar(payload,token){
    return axios.delete(`/${payload.option}/delete`, {
        data:payload.item,
        headers: {
          Authorization: token 
        } 
      });
  },


    //envios
    getConsignmentNoteCategories(headers) {
      return axios.get(`/consignment_notes_categories/`, {
        headers,
      });
    },
  
    getConsignmentNoteSubcategories(payload, headers) {
      return axios.get(
        `/consignment_notes_subcategories/${payload.CATEGORY_ID}`,
        { headers }
      );
    },
  
    getConsignmentNoteClasses(payload, headers) {
      return axios.get(
        `/consignment_notes_classes/${payload.SUBCATEGORY_ID}`,
        { headers }
      );
    },
  
    getConsignmentNotePackagings(headers) {
      return axios.get(`/consignment_notes_packagings/`, {
        headers,
      });
    },
  
    getCarriers(token) {
      let headers = {
        Authorization: token,
      };
  
      return axios.get(`/carriers/`, { headers });
    },
  
    getQuotations(payload, token) {
      let headers = {
        Authorization: token,
      };
      return axios.post(`/quotations/`, payload, {
        headers,
      });
    },


}